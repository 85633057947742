import React, { useState } from 'react'
import Layout from '../components/layouts/courseLandingLayout'
import styled from 'styled-components';
import PSModal from '../components/modal';
import FocusForm from '../components/forms/focusCourseForm';
import Button from '../components/buttons/linkButton';
import { Container, Col, Row } from 'react-bootstrap';
import MattBig from '../assets/images/focus.png';
import Navbar from '../components/navbars/courseLandingNavbarNoMenu';
import ReactGA from 'react-ga'
import Bullet from '../assets/icons/bullet.png';

const CourseContainer = styled.div`
  display: block;
  height: 700px;
  background: none;
  background-image: url(${MattBig});
  background-size: cover;
  background-position: center center;
`
const HeroContainer = styled(Container)`
  padding-top: 250px;
  max-width: 1000px;
  font-family: sans-serif;

  .col-hero-text {
    padding-left: 40px;
    h1 {
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
      color: var(--white);
    }
    .subtext {
      text-align: center;
      color: var(--white);
    }
    .cta-course {
      text-align: center;
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 24px;
    }
    img {
      max-width: 400px;
      display: block;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  } 
`

const FeaturesSection = styled.section`
  padding-bottom: 80px;
  .support-row {
    margin-top: 80px;
    p {
      text-align: left;
    }
    h2 {
      text-align: left;
    }
    .support-text {
      font-size: 16px;
    }
  }
  p {
    padding-top: 20px;
    text-align: center;
  }
  .features-title {
    padding-top: 40px;
    text-align: center;
  }
  .btn-apply-curriculum {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .features {
    color: var(--white);
    background-color: #fff;
    border-bottom: 0;
    padding-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: left;
    position: relative;
    max-width: 870px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .feature {
    width: 50%;
    padding: 10px;
    position: relative;
    padding-left: 20px;

    p {
      padding-bottom: 0;
      margin: 0;
      font-size: 1em;
      background-position: 0 7px;
      background-size: 15px 15px;
      line-height: 25px;
      min-height: 15px;
      text-align: left;
      background-image: url(${Bullet});
      background-repeat: no-repeat;
      padding: 0 0 0 30px;
    }
  }
  .cta-course {
    text-align: center;
  }
`

const Index = ({location}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => setShow(true);

  return (
    <Layout>
      <CourseContainer>
        <Navbar location={location}/>
        <PSModal handleClose={handleClose} handleShow={handleShow} show={show}>
          <FocusForm close={handleClose} />
        </PSModal>
        <HeroContainer>
          <Row>
            <Col className='col-hero-text' md={12}>
              <h1>How to Focus When Learning to Code_</h1>
              <p className='subtext'>A course for mastering the proven stratgies for going from newbie to job-ready Software Engineer.</p>
              <div className='cta-course'>
                <Button href='/apply' text={'Get the Course - FREE'} handleCTAClick={(e) => handleShow(e)} text={'Get the Course - FREE'} />
              </div>
            </Col>
          </Row>
        </HeroContainer>
      </CourseContainer> 
      <FeaturesSection>
        <Container>
          <Row>
            <Col md={12}>
              <h3 className='features-title'>Learning to focus is the key to learning to code.</h3>
              <p>Included in the course...</p>
              <div className='features'>
                <div className='feature'>
                  <p>A template for creating your weekly coding schedule (even if you have a busy life)</p>
                </div>
                <div className='feature'>
                  <p>The 5 steps to setting your goals (land a coding job?)</p>
                </div>
                <div className='feature'>
                  <p>How to making coding a habit with rituals</p>
                </div>
                <div className='feature'>
                  <p>The key to staying focused with the Pomodoro technique</p>
                </div>
                <div className='feature'>
                  <p>Creating a “Life Plan” and how coding fits into it</p>
                </div>
              </div>

              <div className='cta-course'>
                <Button href='/apply' text={'Get the Course - FREE'} handleCTAClick={(e) => handleShow(e)} text={'Get the Course - FREE'} />
              </div>
            </Col>
          </Row>
        </Container>
      </FeaturesSection>
    </Layout>
  )
}

export default Index
